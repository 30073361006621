import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import geoprize from "../../images/geo-prize-graphic.jpg"
import "../../components/prizes.css"
import { StaticImage } from "gatsby-plugin-image"
// images

const GeothermalManu = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Geothermal Manufacturing Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Geothermal Manufacturing Prize (Geothermal
                Prize) is designed to spur innovation and address manufacturing
                challenges fundamental to operating in harsh geothermal
                environments. This prize further supports the ability of the
                geothermal industry to reach the target of 60 GWe of geothermal
                capacity by 2050 as outlined in the recently released GeoVision
                study.{" "}
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/geothermalmanufacturing">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">The Geothermal Manufacturing Prize</h1>
          <p className="pcol-fz">
            As part of the American-Made Challenges series, the Geothermal Prize
            unites the world’s best-in-class research base with the unparalleled
            entrepreneurial support system of the American-Made Network.
            Consisting of pioneering maker spaces, dozens of energy incubators,
            universities, and 17 DOE National Laboratories, the Network is
            primed to create a sweeping portfolio of innovations to demonstrate
            the promise of additive manufacturing.
          </p>

          <h1 className="mac_h1">The Prize Structure</h1>
          <div>
            <div className="row rww">
              <div className="col">
                <p className="pcol-fz">
                  The Geothermal Prize is a series of four progressive
                  competitions that harness the rapid advances that additive
                  manufacturing can provide in tool design, fabrication, and
                  functionality. It incentivizes innovators and entrepreneurs to
                  discover new advanced manufacturing solutions. This rapid,
                  scalable approach to prototype development not only provides
                  cash prizes, but also engages America’s unique innovation
                  ecosystem to help participants achieve their goals.
                </p>
                <button type="button" className="btns" disabled>
                  Learn about competing in the E-ROBOT Prize{" "}
                </button>
              </div>
              <div className="col">
                <StaticImage
                  src="../../images/geo-prize-graphic.jpg"
                  className="img-styl"
                  alt="geo prize graphic"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div className="container">
          <div>
            <h1 className="mac_h1">
              Congratulations Set! Contest Semifinalists!
            </h1>
            <hr />
            <h2>
              {" "}
              Congratulations to all of the winners from the Set! Contest! These
              10 teams were recipients of a cash prize and advanced to the Make!
              Contest.
            </h2>

            <table class="table table-hover table-responsive-sm">
              <thead>
                <tr>
                  <th>
                    entry title
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically by team)
                    </span>
                  </th>
                  <th className="tds">team</th>
                  <th className="tds">location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/33673"
                      className="has-text-bright"
                    >
                      A Novel Generator for Underground Environments
                    </a>
                  </td>
                  <td className="tds">AC</td>
                  <td className="tds">Cambridge, MA</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34725"
                      className="has-text-bright"
                    >
                      Additive Manufactured Backup-Rings for Packers
                    </a>
                  </td>
                  <td className="tds">Baker Hughes</td>
                  <td className="tds">Houston, TX</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34836"
                      className="has-text-bright"
                    >
                      A new Isolation Packer System for Geothermal Wells
                    </a>
                  </td>
                  <td className="tds">Downhole Emerging Technologies</td>
                  <td className="tds">Houston, TX</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34835"
                      className="has-text-bright"
                    >
                      Additively Manufactured Mechanical Metamaterials
                    </a>
                  </td>
                  <td className="tds">Multiscale Systems</td>
                  <td className="tds">Worcester, MA</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34860"
                      className="has-text-bright"
                    >
                      Using AM to rapidly deploy new sensors into EGS
                    </a>
                  </td>
                  <td className="tds">Ozark Integrated Circuits</td>
                  <td className="tds">Fayetteville, AR</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34845"
                      className="has-text-bright"
                    >
                      Optimization of Gas Sparger Head for Airlift
                    </a>
                  </td>
                  <td className="tds">PLUGS</td>
                  <td className="tds">Morgantown, WV</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34858"
                      className="has-text-bright"
                    >
                      3D Printing of High-Temperature Elastomers for EGS
                    </a>
                  </td>
                  <td className="tds">Schlumberger</td>
                  <td className="tds">Houston, TX</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34528"
                      className="has-text-bright"
                    >
                      Project Hot Hammer
                    </a>
                  </td>
                  <td className="tds">The Bit Guys</td>
                  <td className="tds">Morgantown, WV</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34855"
                      className="has-text-bright"
                    >
                      Ultra-High Temperature Logging Tool Set Submission
                    </a>
                  </td>
                  <td className="tds">Ultra-High Temperature Logging Tool</td>
                  <td className="tds">Houston, TX</td>
                </tr>
                <tr>
                  <td className="">
                    <a
                      href="https://www.herox.com/GeothermalManufacturing/round/783/entry/34827"
                      className="has-text-bright"
                    >
                      Novel Metal Annular Barrier for Geothermal Systems
                    </a>
                  </td>
                  <td className="tds">Welltec</td>
                  <td className="tds">Katy, TX</td>
                </tr>
              </tbody>
            </table>
            <div className="has-text-centered">
              <a
                href="https://www.herox.com/GeothermalManufacturing/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View Set! Contest Semifinalists on HeroX
                </button>
              </a>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <h1 className="mac_h1">
            Congratulations to out Ready! Contest Winners!
          </h1>
          <hr />
          <p className="pcol-fz">
            Congratulations to all of the winners from the Ready! Contest! These
            15 teams were recipients of a cash prize.
          </p>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  entry title{" "}
                  <span class="is-size-7 has-text-weight-normal">
                    (listed alphabetically)
                  </span>
                </th>
                <th className="tds">team</th>
                <th className="tds">location</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32397"
                    className="has-text-bright"
                  >
                    3D Printed Frac plug for Geothermal Applications
                  </a>
                </td>
                <td className="tds">
                  Team Schlumberger - Led by Bhushan Pendse
                </td>
                <td className="tds">Houston, TX</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32414"
                    className="has-text-bright"
                  >
                    3D Printing of High-Temperature Elastomers
                  </a>
                </td>
                <td className="tds">Team Schlumberger - Led by Huilin Tu</td>
                <td className="tds">Houston, TX</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/30404"
                    className="has-text-bright"
                  >
                    Additive Manufactured Backup-Rings for Packers
                  </a>
                </td>
                <td className="tds">Team Baker Hughes</td>
                <td className="tds">Houston, TX</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/31007"
                    className="has-text-bright"
                  >
                    Additively Manufactured Mechanical Metamaterials
                  </a>
                </td>
                <td className="tds">Multiscale Systems</td>
                <td className="tds">Worcester, MA</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32385"
                    className="has-text-bright"
                  >
                    Capturing Energy in Extreme Downhole Conditions
                  </a>
                </td>
                <td className="tds">Dr. Edem Tsikata</td>
                <td className="tds">Cambridge, MA</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/31164"
                    className="has-text-bright"
                  >
                    Closed Loop Helical Downhole Heat Exchanger
                  </a>
                </td>
                <td className="tds">Sage Geosystems</td>
                <td className="tds">Houston, TX</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32398"
                    className="has-text-bright"
                  >
                    Device to Remove Scale in Geothermal Wells by A.M.
                  </a>
                </td>
                <td className="tds">Dreamer Geothermophiles</td>
                <td className="tds">Grand Forks, ND</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32408"
                    className="has-text-bright"
                  >
                    Geothermal 3-Axis Wideband Vibration-Accelerometer
                  </a>
                </td>
                <td className="tds">Dr. Harold Snyder</td>
                <td className="tds">Rockwall, TX</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/31990"
                    className="has-text-bright"
                  >
                    HyperDrilled &amp; 3D printed concrete CWD geo wells
                  </a>
                </td>
                <td className="tds">Team Hypersciences</td>
                <td className="tds">Spokane, WA</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32381"
                    className="has-text-bright"
                  >
                    Innovation: Expanding Geothermal Energy Solutions
                  </a>
                </td>
                <td className="tds">GreenFire Energy</td>
                <td className="tds">Emeryville, CA</td>
              </tr>
              <tr>
                <td className="tds">
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32399"
                    className="has-text-bright"
                  >
                    Novel Metal Annular Barrier for Geothermal Systems
                  </a>
                </td>
                <td className="tds">Welltec</td> <td>Richmond, TX</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32412"
                    className="has-text-bright"
                  >
                    Optimization of Gas Sparger Head for Airlift
                  </a>
                </td>
                <td className="tds">Team PLUGS</td>
                <td className="tds">Morgantown, WV</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/29201"
                    className="has-text-bright"
                  >
                    Project Hot Hammer
                  </a>
                </td>
                <td className="tds">The Bit Guys</td>
                <td className="tds">Morgantown, WV</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32396"
                    className="has-text-bright"
                  >
                    Ultra High Temperature Logging Tool
                  </a>
                </td>
                <td className="tds">
                  Team Ultra High-Temperature Logging Tool
                </td>
                <td className="tds">Houston, TX</td>
              </tr>
              <tr>
                <td>
                  <a
                    href="https://www.herox.com/GeothermalManufacturing/round/604/entry/32415"
                    className="has-text-bright"
                  >
                    Using AM to rapidly deploy new sensors into EGS
                  </a>
                </td>
                <td className="tds">Ozark Integrated Circuits</td>
                <td className="tds">Fayetteville, AR</td>
              </tr>
            </tbody>
          </table>
          <div className="has-text-centered">
            <a
              href="https://www.herox.com/GeothermalManufacturing/community"
              target="_blank"
            >
              <button type="button" className="btns">
                View Set! Contest Semifinalists on HeroX
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <hr />
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Prize Announced:</b>
                January 2020
              </li>
              <hr />
              <li>
                <b>Rules Feedback Request:</b>
              </li>
              <ul>
                <li>Opened: January, 2020</li>
                <li>Closed: April, 2020</li>
              </ul>
            </ul>
            <hr />
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Ready! Contest</b> closed
              </li>
              <ul>
                <li>Opened: April 29, 2020</li>
                <li>Closed: August 26, 2020 at 5 p.m. ET</li>
                <li>Ready! Contest winners announced: October 18, 2020</li>
              </ul>
            </ul>
            <hr />
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Set! Contest</b> closed
              </li>
              <ul>
                <li>Opened: October 18, 2020</li>
                <li>
                  Set! Competitor AM design workshop at the ORNL MDF: November
                  4–6, 2020
                </li>
                <li>Submission deadline: February 17, 2021 at 5 p.m. ET</li>
                <li>Set! Contest winners announced: March 31, 2021</li>
              </ul>
            </ul>
            <hr />
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Make! Contest</b>
              </li>
              <ul>
                <li>Opened: March 31, 2021</li>
                <li>Submission deadline: November 2021</li>
                <li>
                  Make! Contest winners announced at live demo day: January 2022
                  anticipated
                </li>
              </ul>
            </ul>
            <hr />
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Geo! Contest</b>
              </li>
              <ul>
                <li>Opens: January 2022 anticipated</li>
                <li>Submission deadline: May 2022 anticipated</li>
                <li>
                  Geo! Contest winners announced at live demo day: July 2022
                  anticipated
                </li>
              </ul>
            </ul>
            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/geothermalmanufacturing"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
            <b class="is-size-7">
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
          </div>
          <hr />
        </div>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/geothermalmanufacturing">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default GeothermalManu
